import * as FullStory from '@fullstory/browser'

export const bootSession = function (session) {
  const featureFlags = session.get('feature_flags') || session.get('whitelist')

  if (window.rollbar) {
    window.rollbar.configure({
      payload: {
        person: {
          id: session.get('_id'),
          email: session.get('email')
        }
      }
    })
  }

  if (featureFlags.includes('session_recording')) {
    FullStory.init({ orgId: 'PRRPK', debug: true })
    FullStory.identify(session.get('_id'), {})
  }
}
