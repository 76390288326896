/**
*
* Subscription Components
*
*/

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { format } from 'date-fns'
import Button from 'components/Button'
import Loading from 'components/Loading'
import NumberBox from 'elements/NumberBox'
import Label from 'elements/Label'
import CardImage from 'elements/CardImage'
import theme from '../../../../themes/light'

const Wrapper = styled.div`
  max-width: 600px;
  flex: 1 1 auto;
  flex-direction: column;
  display: flex;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 2rem;
`

const InnerWrapper = styled.div`
  background: ${theme.colors.white};
  box-shadow: ${theme.shadow};
  width: 100%;
  max-width: 600px;
  flex: 1 1 auto;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
`

const Title = styled.h3`
  color: ${theme.titleColor};
  font: ${theme.fonts.header};
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
`

const TitleContainer = styled.div`
  background: ${theme.colors.gray10};
  padding: 1rem 2rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const ButtonContainer = styled.div`

`

const ContentContainer = styled.div`
  width: 100%;
  padding: 1rem 0rem;
`

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;

  svg {
    margin-bottom: 0.1rem;
  }
`

const BoxContainer = styled.div`
  padding: 0.75rem 2rem;
  display: flex;
  flex-direction: row;
  justify-content: ${props => (props.isOwner ? 'space-between' : 'center')};
`

class Subscription extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true,
      requests: null
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    // choose loading indicator
    const loading = nextProps.subscription.get('loading') || nextProps.customer.get('loading') ||
      nextProps.preview.get('loading') || (!this.state.requests && nextProps.requests.get('loading'))

    this.setState({ loading })

    if (!nextProps.requests.get('loading')) {
      if (nextProps.requests.getIn(['data', 'cycles_ago']) === 0) {
        this.setState({ requests: nextProps.requests })
      }
    }
  }

  render () {
    const {
      subscription,
      subscribed,
      customer,
      preview,
      isOwner,
      owner,
      onCancel,
      onUpdateBilling,
      onAddSeats
    } = this.props

    const {
      loading,
      requests
    } = this.state

    if (loading || !owner) {
      return (
        <Wrapper>
          <InnerWrapper>
            <TitleContainer>
              <Title>Subscription</Title>
            </TitleContainer>
            <Loading padding='4rem' />
          </InnerWrapper>
        </Wrapper>
      )
    }

    let label = null
    let chargeLabel = ''

    const collectionMethod = subscription.getIn(['data', 'collection_method'])
    const source = customer.getIn(['data', 'sources', 0])

    if (!isOwner) {
      const ownerEmail = owner.get('email')
      const ownerName = owner.get('full_name')
      label = (
        <span>
          {subscribed ? 'You\'re onboard Interseller!' : 'Subscription expired.'}
          <br />
          {`Your account is managed by ${ownerName} (${ownerEmail}).`}
        </span>
      )
    } else {
      if (collectionMethod === 'send_invoice') {
        /* eslint-disable */
        label = (
          <span>
            You are currently being billed through invoices, if you require any subscription changes,
              please <a href="https://help.interseller.io">reach out</a> to us!
          </span>
        );
        /* eslint-enable */
      } else if (source) {
        chargeLabel = (
          <CardContainer>
            <CardImage height={16} cardType={source.get('brand')} />
            <span style={{ marginLeft: '0.3rem' }}>
              {`*${source.get('last4')}`}
            </span>
          </CardContainer>
        )
      }
    }

    const quantity = subscription.getIn(['data', 'quantity'])
    const totalRequests = requests.getIn(['data', 'totals', 'total'])
    const requestLimit = requests.getIn(['data', 'limit'])
    const remainingLookups = requestLimit - totalRequests
    const lookupResetRaw = requests.getIn(['data', 'resets_on'])
    const lookupReset = format(new Date(lookupResetRaw), 'M[/]D')
    const lookupResetHour = format(new Date(lookupResetRaw), 'M[/]D[/]YY h:mm A')
    const billingDateRaw = preview.getIn(['data', 'later_at']) || subscription.getIn(['data', 'current_period_end'])
    const billingDate = format(new Date(billingDateRaw * 1000), 'M[/]D[/]YY')
    const charge = preview.getIn(['data', 'later'])
    const hasCanceled = subscription.getIn(['data', 'cancel_at_period_end'])
    const cancelDateRaw = hasCanceled && subscription.getIn(['data', 'current_period_end'])
    const cancelDate = hasCanceled && format(new Date(cancelDateRaw * 1000), 'M[/]D[/]YY')

    return (
      <Wrapper>
        <InnerWrapper>
          <TitleContainer>
            <Title>Subscription</Title>
            {isOwner &&
              <ButtonContainer>
                <Button
                  label='Update Billing Info'
                  small
                  ml='0.5rem'
                  handleClick={onUpdateBilling}
                />
                <Button
                  primary
                  label='Adjust Total Seats'
                  small
                  ml='0.5rem'
                  handleClick={onAddSeats}
                />
              </ButtonContainer>}
          </TitleContainer>
          <ContentContainer>
            {label &&
              <Label mt='0.5rem' mb='0.5rem' pl='2rem' pr='2rem' align='center'>
                {label}
              </Label>}
            {!subscribed && !isOwner &&
              <Label mt='0.5rem' mb='0.5rem' pl='2rem' pr='2rem' align='center'>
                <span>{`Contact ${owner.get('full_name')} to subscribe or reach out to `}<a href='mailto:hi@interseller.io' target='_blank' rel='noopener noreferrer'>support</a>!</span>
              </Label>}
            {subscribed &&
              <>
                <BoxContainer isOwner={isOwner}>
                  {isOwner &&
                    <NumberBox
                      label='number of seats'
                      value={quantity}
                      padding='0.75rem 0.45rem'
                    />}
                  <NumberBox
                    label='remaining lookups'
                    value={remainingLookups}
                    suffix={lookupReset ? `resets ${lookupReset}` : ''}
                    tooltip={lookupResetHour ? `Resets on ${lookupResetHour}` : ''}
                    padding='0.75rem 0.45rem'
                  />
                  {isOwner && !hasCanceled &&
                    <NumberBox
                      label={`next payment · ${billingDate}`}
                      currency
                      value={charge}
                      suffix={chargeLabel}
                      tooltip='The upcoming payment might not account for any pending credits in your account.'
                      padding='0.75rem 0.45rem'
                    />}
                  {hasCanceled &&
                    <NumberBox
                      label={`canceled as of · ${cancelDate}`}
                      padding='0.75rem 0.45rem'
                    />}
                </BoxContainer>
                {isOwner && !hasCanceled && (
                  <Button
                    label='Cancel Subscription'
                    small
                    align='center'
                    handleClick={onCancel}
                  />
                )}
              </>}
          </ContentContainer>
        </InnerWrapper>
      </Wrapper>
    )
  }
}

Subscription.propTypes = {
  subscription: PropTypes.object,
  subscribed: PropTypes.bool,
  customer: PropTypes.object,
  preview: PropTypes.object,
  requests: PropTypes.object,
  owner: PropTypes.object,
  isOwner: PropTypes.bool,
  onCancel: PropTypes.func,
  onUpdateBilling: PropTypes.func,
  onAddSeats: PropTypes.func
}

export default Subscription
